import { useState, useEffect } from 'react';
import { db, functions } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FaLink, FaPaintBrush, FaStore, FaShareAlt, FaExternalLinkAlt, FaShare, FaEnvelope } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Linksy from './LinksyPreview';
import LinksManager from './LinksManager';
import LinksyDesign from './DesignManager';
import ShopManager from './ShopManager';
import SocialManager from './SocialManager';
import Profile from './Profile';
import LoadingSpinner from './Components/LoadingSpinner';
import './Admin.css';
import MessagesManager from './MessagesManager';


function Admin() {
    const [linksyData, setLinksyData] = useState(null);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeManager, setActiveManager] = useState('links'); // 'links' or 'design'
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [tokenData, setTokenData] = useState(null);
    const auth = getAuth();
    const navigate = useNavigate();
    const [listingsOffset, setListingsOffset] = useState(0);
    const [hasMoreListings, setHasMoreListings] = useState(true);
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                navigate('/app/auth');
            }
        });

        return () => unsubscribe();
    }, [auth, navigate]);

    const fetchListings = async (offset = 0, currentUserData = userData, currentTokenData = tokenData) => {
        try {
            
            if (!currentUserData?.shop_id) {
                console.log('No shop_id found in userData');
                return;
            }
            if (!currentTokenData?.access_token) {
                console.log('No access_token found in tokenData');
                return;
            }
            
            const fetchShopListings = httpsCallable(functions, 'fetchShopListings');
            console.log('Calling fetchShopListings with:', {
                shopId: currentUserData.shop_id,
                offset
            });
            
            const result = await fetchShopListings({ 
                shopId: currentUserData.shop_id,
                token: currentTokenData.access_token,
                limit: 100,
                offset: offset
            });
            
            console.log('Listings fetched:', result.data);
            setListings(prevListings => [...prevListings, ...(result.data.results || [])]);
            
            // Update hasMoreListings and offset
            setHasMoreListings(result.data.results.length === 100);
            if (result.data.results.length === 100) {
                setListingsOffset(prevOffset => prevOffset + 100);
            }
        } catch (error) {
            console.error('Error in fetchListings:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!user) return;

                setLoading(true);
                console.log('Starting initial data fetch...');

                // Fetch all required documents
                const [linksyDoc, userDoc, tokenDoc] = await Promise.all([
                    getDoc(doc(db, 'linksys', user.uid)),
                    getDoc(doc(db, 'users', user.uid)),
                    getDoc(doc(db, 'tokens', user.uid))
                ]);

                if (linksyDoc.exists()) {
                    const linksyData = linksyDoc.data();
                    const userData = userDoc.data();
                    const tokenData = tokenDoc.data();
                    
                    setUserData(userData);
                    setTokenData(tokenData);
                    setLinksyData(linksyData);

                    // Reset listings state before fetching new ones
                    setListings([]);
                    setListingsOffset(0);
                    setHasMoreListings(true);

                    // Fetch initial listings
                    if (userData?.shop_id && tokenData?.access_token) {
                        await fetchListings(0, userData, tokenData);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    // Add a new useEffect to check for delisted items whenever listings change
    useEffect(() => {
        const checkDelistedItems = async () => {
            if (!linksyData?.selectedListings || !listings.length) return;

            const activeListingIds = new Set(listings.map(l => l.listing_id));
            const updatedSelectedListings = linksyData.selectedListings.filter(
                selected => activeListingIds.has(selected.listing_id)
            );

            // If any listings were removed, update Firestore
            if (updatedSelectedListings.length !== linksyData.selectedListings.length) {
                console.log('Removing delisted items...');
                console.log('Previous selected listings:', linksyData.selectedListings);
                console.log('Updated selected listings:', updatedSelectedListings);

                const updatedData = {
                    ...linksyData,
                    selectedListings: updatedSelectedListings
                };

                try {
                    await updateDoc(doc(db, 'linksys', user.uid), updatedData);
                    setLinksyData(updatedData);
                } catch (error) {
                    console.error('Error updating delisted items:', error);
                }
            }
        };

        checkDelistedItems();
    }, [listings, linksyData?.selectedListings]);

    const handleLinksUpdate = async (newLinks) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedData = {
                ...linksyData,
                links: newLinks
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData);
        } catch (error) {
            console.error('Error updating links:', error);
        }
    };

    const handleDesignUpdate = async (newDesign) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedData = {
                ...linksyData,
                ...newDesign
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData);
        } catch (error) {
            console.error('Error updating design:', error);
        }
    };

    const handleAboutUpdate = async (newAbout) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedData = {
                ...linksyData,
                bio: newAbout
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData); 
        } catch (error) {
            console.error('Error updating about:', error);
        }
    };

    const handleListingToggle = async (listing) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedListings = linksyData.selectedListings || [];
            const existingIndex = updatedListings.findIndex(l => l.listing_id === listing.listing_id);

            if (existingIndex >= 0) {
                updatedListings.splice(existingIndex, 1);
            } else {
                // Keep the entire listing object
                updatedListings.push(listing);
            }

            const updatedData = {
                ...linksyData,
                selectedListings: updatedListings
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData);
        } catch (error) {
            console.error('Error updating selected listings:', error);
        }
    };

    const handleSocialUpdate = async (newSocialLinks) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedData = {
                ...linksyData,
                socialLinks: newSocialLinks
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData);
        } catch (error) {
            console.error('Error updating social links:', error);
        }
    };

    const copyToClipboard = async () => {
        try {
            const url = `${window.location.origin}/${linksyData?.shopName}`;
            await navigator.clipboard.writeText(url);
            // You might want to add a toast notification here
            alert('Link copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    if (loading || !linksyData) {
        return <LoadingSpinner />;
    }

    return (
        <div className="admin-container">
            {/* Navigation Column */}
            <div className="admin-nav">
                <div className="admin-nav-header">
                    <div className="admin-logo" onClick={() => navigate('/app/admin')}>Linksy</div>
                </div>
                
                <div className="admin-nav-buttons">
                    <button 
                        className={`nav-button ${activeManager === 'links' ? 'active' : ''}`}
                        onClick={() => setActiveManager('links')}
                    >
                        <FaLink style={{ marginRight: '8px' }} /> Links
                    </button>
                    <button 
                        className={`nav-button ${activeManager === 'design' ? 'active' : ''}`}
                        onClick={() => setActiveManager('design')}
                    >
                        <FaPaintBrush style={{ marginRight: '8px' }} /> Design
                    </button>
                    <button
                        className={`nav-button ${activeManager === 'shop' ? 'active' : ''}`}
                        onClick={() => setActiveManager('shop')}
                    >
                        <FaStore style={{ marginRight: '8px' }} /> Shop
                    </button>
                    <button
                        className={`nav-button ${activeManager === 'social' ? 'active' : ''}`}
                        onClick={() => setActiveManager('social')}
                    >
                        <FaShareAlt style={{ marginRight: '8px' }} /> Social
                    </button>
                    <button
                        className={`nav-button ${activeManager === 'messages' ? 'active' : ''}`}
                        onClick={() => setActiveManager('messages')}
                    >
                        <FaEnvelope style={{ marginRight: '8px' }} /> Messages
                    </button>
                </div>

                <div className="admin-nav-footer">
                    <Profile shopName={linksyData?.shopName} />
                </div>
            </div>

            {/* Manager Column */}
            <div className="admin-manager">
                {activeManager === 'links' ? (
                    <LinksManager 
                        links={linksyData?.links || []}
                        onUpdate={handleLinksUpdate}
                    />
                ) : activeManager === 'design' ? (
                    <LinksyDesign 
                        design={linksyData}
                        onUpdate={handleDesignUpdate}
                    />
                ) : activeManager === 'shop' ? (
                    <ShopManager 
                        about={linksyData?.bio}
                        listings={listings}
                        selectedListings={linksyData?.selectedListings || []}
                        onUpdate={handleAboutUpdate}
                        onListingToggle={handleListingToggle}
                        fetchMoreListings={() => fetchListings(listingsOffset)}
                        hasMoreListings={hasMoreListings}
                    />
                ) : activeManager === 'social' ? (
                    <SocialManager 
                        socialLinks={linksyData?.socialLinks || []}
                        onUpdate={handleSocialUpdate}
                        userData={userData}
                    />
                ) : activeManager === 'messages' ? (
                    <MessagesManager userId={user.uid} />
                ) : null}
            </div>

            {/* Preview Column */}
            <div className="admin-preview">
                <div className="iphone-mockup">
                    <div className="iphone-screen">
                        <Linksy data={linksyData} />
                    </div>
                </div>
                <div className="preview-actions">
                    <a 
                        href={`/${linksyData?.shopName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="preview-button"
                    >
                        <FaExternalLinkAlt />
                        Preview
                    </a>
                    <button 
                        onClick={copyToClipboard}
                        className="preview-button"
                    >
                        <FaShare />
                        Share
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Admin;