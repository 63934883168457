import { useState, useEffect, useRef } from 'react';
import { db } from '../../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import './ContactForm.css';

function ContactForm({ etsyUserId, uid, onClose, isOpen, shopName }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [status, setStatus] = useState('idle');
    const formRef = useRef(null);
    const [successBanner, setSuccessBanner] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setFormData({
                name: '',
                email: '',
                message: ''
            });
            setStatus('idle');
        }
    }, [isOpen]);

    // Handle click outside
    useEffect(() => {
        function handleClickOutside(event) {
            // Don't handle click-outside if clicking the contact button
            if (event.target.closest('.contact-button-container')) {
                return;
            }
            
            if (formRef.current && !formRef.current.contains(event.target)) {
                onClose();
            }
        }

        if (isOpen) {
            // Small delay to prevent immediate closure
            setTimeout(() => {
                document.addEventListener('mousedown', handleClickOutside);
            }, 100);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('sending');

        try {
            if (!uid) {
                throw new Error('Seller ID is missing');
            }

            const messageId = `msg_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
            
            const messageData = {
                ...formData,
                uid,
                messageId,
                timestamp: new Date(),
                read: false
            };

            if (etsyUserId) {
                messageData.etsyUserId = etsyUserId;
            }
            
            await addDoc(collection(db, 'messages'), messageData);
            
            setStatus('success');
            setSuccessBanner(true);
            onClose();
            
            setTimeout(() => {
                setSuccessBanner(false);
            }, 3000);
        } catch (error) {
            console.error('Error sending message:', error);
            setStatus('error');
        }
    };

    return (
        <>
            {successBanner && (
                <div style={{
                    margin: '8px 0',
                    padding: '12px',
                    textAlign: 'center',
                    backgroundColor: '#d4edda',
                    color: '#155724',
                    borderRadius: '8px',
                }}>
                    Message sent successfully!
                </div>
            )}
            
            <div className="contact-form-overlay">
                <div 
                    ref={formRef}
                    className={`contact-form-modal ${isOpen ? 'open' : ''}`}
                >
                    <div className="form-content">
                        <h3>Message {shopName}</h3>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                                required
                            />
                            <textarea
                                placeholder="Your Message"
                                value={formData.message}
                                onChange={(e) => setFormData({...formData, message: e.target.value})}
                                required
                            />
                            <button 
                                type="submit" 
                                disabled={status === 'sending'}
                            >
                                {status === 'sending' ? 'Sending...' : 'Send Message'}
                            </button>
                            
                            {status === 'error' && (
                                <div className="error-message">
                                    Failed to send message. Please try again.
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactForm; 