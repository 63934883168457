import { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, query, where, orderBy, getDocs, updateDoc, doc } from 'firebase/firestore';
import './MessagesManager.css';
import LoadingSpinner from './Components/LoadingSpinner';

function MessagesManager({ userId }) {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [copiedId, setCopiedId] = useState(null);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const q = query(
                    collection(db, 'messages'),
                    where('uid', '==', userId),
                    orderBy('timestamp', 'desc')
                );
                
                const querySnapshot = await getDocs(q);
                const fetchedMessages = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    timestamp: doc.data().timestamp?.toDate()
                }));
                
                setMessages(fetchedMessages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();
    }, [userId]);

    const markAsRead = async (messageId) => {
        try {
            await updateDoc(doc(db, 'messages', messageId), {
                read: true
            });
            
            setMessages(messages.map(msg => 
                msg.id === messageId ? {...msg, read: true} : msg
            ));
        } catch (error) {
            console.error('Error marking message as read:', error);
        }
    };

    const copyToClipboard = async (email, messageId, event) => {
        event.stopPropagation();
        try {
            await navigator.clipboard.writeText(email);
            setCopiedId(messageId);
            setTimeout(() => {
                setCopiedId(null);
            }, 1000); // Reset after 2 seconds
        } catch (error) {
            console.error('Failed to copy email:', error);
        }
    };

    if (loading) return <LoadingSpinner />;

    return (
        <div className="messages-manager">
            <h4>Messages</h4>
            
            <div className="messages-list">
                {messages.length === 0 ? (
                    <p>No messages yet.</p>
                ) : (
                    messages.map(message => (
                        <div 
                            key={message.id} 
                            className={`message-item ${!message.read ? 'unread' : ''}`}
                            onClick={() => !message.read && markAsRead(message.id)}
                        >
                            <div className="message-header">
                                <span className="message-from">{message.name}</span>
                                <span className="message-date">
                                    {message.timestamp.toLocaleDateString()}
                                </span>
                            </div>
                            <div className="message-email">
                                {message.email}
                                <button 
                                    className={`copy-button ${copiedId === message.id ? 'copied' : ''}`}
                                    onClick={(e) => copyToClipboard(message.email, message.id, e)}
                                    title="Copy email"
                                >
                                    {copiedId === message.id ? (
                                        <span className="copied-text">Copied!</span>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                    )}
                                </button>
                            </div>
                            <div className="message-content">{message.message}</div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default MessagesManager; 